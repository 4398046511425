@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full w-full;
  }

  html {
    @apply text-[10px];
    @apply bg-background-primary-dark;
  }

  body {
    @apply cursor-default text-[1.6rem] antialiased;
    @apply selection:bg-background-primary-dark selection:text-text-primary-dark dark:selection:bg-background-primary-light dark:selection:text-text-primary-light;
  }

  #__next {
    @apply static h-full w-full;
  }

  .shopify-product-button {
    @apply flex border border-surface-primay-light bg-surface-primay-light p-[1.2rem] text-text-button-primary-light dark:border-surface-primay-dark dark:bg-surface-primay-dark dark:text-text-button-primary-dark;
  }

  .shopify-product-button > span {
    @apply relative block overflow-hidden p-[.4rem] after:absolute after:left-0 after:top-0 after:-mb-[0.35em] after:-mt-[0.3em] after:w-full after:translate-y-full after:p-[.4rem] after:font-roboto after:text-small after:transition-transform after:duration-300 after:ease-out-cubic after:content-[attr(data-label)] pointer:group-hover:after:translate-y-0;
  }

  .shopify-product-button > span > span {
    @apply -mb-[.35em] -mt-[.3em] block font-roboto text-small transition-transform duration-300 ease-out-cubic pointer:group-hover:-translate-y-full;
  }

  .shopify-product-button:hover > span {
    @apply after:translate-y-0;
  }

  .shopify-product-button:hover > span > span {
    @apply -translate-y-full;
  }
}

html {
  scrollbar-width: none;
}

html::-webkit-scrollbar {
  display: none;
}

.lenis {
  scrollbar-width: none;
}

.lenis::-webkit-scrollbar {
  display: none;
}

.line-wrap {
  overflow: hidden;
}

.word {
  white-space: nowrap;
}

.shopify-buy-frame--toggle,
.shopify-buy-frame--modal,
.shopify-buy-frame--cart {
  z-index: 20 !important;
}

input[type='date']::-webkit-date-and-time-value {
  text-align: left;
}
